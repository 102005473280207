import { createSelector } from '@ngrx/store';
import {
  Event,
  Genre,
  Language,
  Location,
  Role,
  Tag,
} from '../../models/app.models';
import { appSelectors } from './index';

export * as appCustomSelectors from './app.selectors';

export const selectLoggedIn = createSelector(
  appSelectors.selectLoggedIn,
  (loggedIn: boolean) => loggedIn,
);

export const selectLanguage = createSelector(
  appSelectors.selectLanguage,
  (language: Language) => language,
);

export const selectRoles = createSelector(appSelectors.selectRoles, (roles) => {
  if (!roles || roles.length <= 0) {
    return [];
  }

  return roles.map((role: Role) => role.name);
});

export const selectCustomEvents = createSelector(
  appSelectors.selectEvents,
  (events): Event[] => {
    if (!events || events.length <= 0) {
      return [];
    }

    return events.filter((event) => {
      if (event.eventStatus === 'online' || event.eventStatus === 'archived') {
        return !events.some(
          (p) =>
            (p.eventStatus === 'pending' || p.eventStatus === 'declined') &&
            p.eventId === event.id,
        );
      }
      return true;
    });
  },
);

export const selectUsedGenres = createSelector(
  appSelectors.selectEvents,
  (events): Genre[] => {
    if (!events || events.length <= 0) {
      return [];
    }

    const genres: Genre[] = [];

    events.forEach((event) => {
      if (event.genres && event.genres.length > 0) {
        // Assuming event.genre is an array of genres
        event.genres.forEach((individualGenre) => {
          if (
            genres.filter(
              (existingGenre) => existingGenre.id === individualGenre.id,
            ).length === 0
          ) {
            genres.push(individualGenre);
          }
        });
      }
    });

    return genres;
  },
);

export const selectUsedTags = createSelector(
  appSelectors.selectEvents,
  (events): Tag[] => {
    if (!events || events.length <= 0) {
      return [];
    }

    const tags: Tag[] = [];

    events.forEach((event) => {
      if (event.tags && event.tags.length > 0) {
        // Assuming event.tag is an array of tags
        event.tags.forEach((individualTag) => {
          if (
            tags.filter((existingTag) => existingTag.id === individualTag.id)
              .length === 0
          ) {
            tags.push(individualTag);
          }
        });
      }
    });

    return tags;
  },
);

export const selectMinAge = createSelector(
  appSelectors.selectEvents,
  (events): string[] => {
    if (!events || events.length <= 0) {
      return [];
    }

    const ageRestrictionFilterOptions: Set<string> = new Set();

    events.forEach((event) => {
      ageRestrictionFilterOptions.add(event.ageRestrictionMen);
      ageRestrictionFilterOptions.add(event.ageRestrictionWomen);
    });

    return Array.from(ageRestrictionFilterOptions);
  },
);

export const selectLocations = createSelector(
  appSelectors.selectEvents,
  (events): Location[] => {
    if (!events || events.length <= 0) {
      return [];
    }

    const locationList: Location[] = [];

    events.forEach((event) => {
      if (event.location) {
        if (
          locationList.filter((location) => location.id === event.location.id)
            .length === 0
        ) {
          locationList.push(event.location);
        }
      }
    });

    return locationList;
  },
);

export const selectStatuses = createSelector(
  appSelectors.selectEvents,
  (events): string[] => {
    if (!events || events.length <= 0) {
      return [];
    }

    const statusList: string[] = [];

    events.forEach((event) => {
      if (event.eventStatus) {
        if (
          statusList.filter((status) => status === event.eventStatus).length ===
          0
        ) {
          statusList.push(event.eventStatus);
        }
      }
    });

    return statusList;
  },
);
